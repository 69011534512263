import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const Header = () => {
  return (
    <AppBar 
      position="static" 
      sx={{ 
        backgroundColor: '#21456d', // Dark blue, similar to TabBar
        color: 'white', // White text for contrast
        borderTopLeftRadius: 10, // Rounded top-left corner
        borderTopRightRadius: 10, // Rounded top-right corner
        boxShadow: 'none', // Optional: remove shadow for a flatter design
      }}
    >
      <Toolbar variant="dense">
        <Typography 
          variant="h6" 
          color="inherit" 
          sx={{ 
            flexGrow: 1, 
            textAlign: 'center',
            fontWeight: 'bold', // Optional: make the title bold
          }}
        >
          WebPad++
        </Typography>                  
      </Toolbar>
    </AppBar>
  );
};

export default Header;
