import { makeAutoObservable } from 'mobx';

class DocumentStore {
  documents = [];
  activeDocumentIndex = 0;

  constructor() {
    makeAutoObservable(this);
    this.loadInitialData();
    this.addDocument = this.addDocument.bind(this);
    this.onCloseTab = this.onCloseTab.bind(this);
    this.renameDocument = this.renameDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.updateDocumentContent = this.updateDocumentContent.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.setActiveDocumentIndex = this.setActiveDocumentIndex.bind(this);
  }

  loadInitialData() {
    const savedMetadata = localStorage.getItem('documentMetadata');
    this.documents = savedMetadata ? JSON.parse(savedMetadata) : [this.createNewDocument(1)];
    this.activeDocumentIndex = parseInt(localStorage.getItem('activeDocumentIndex'), 10) || 0;
  }

  createNewDocument(nameSuffix, language = 'Text') {
    return {
      name: `Untitled-${nameSuffix}`,
      language,
      id: Date.now().toString(36) + Math.random().toString(36).substr(2),
      content: ''
    };
  }

  addDocument = () => {
    const newDocument = this.createNewDocument(this.documents.length + 1);
    this.documents.push(newDocument);
    this.setActiveDocumentIndex(this.documents.length - 1);
    this.persistDocuments();
  };

  setActiveDocumentIndex(index) {
    this.activeDocumentIndex = index;
    localStorage.setItem('activeDocumentIndex', index.toString());
  }

  updateDocumentContent(newContent) {
    const activeDocument = this.documents[this.activeDocumentIndex];
    if (activeDocument) {
      activeDocument.content = newContent;
      this.persistDocuments();
    }
  }

  updateDocumentLanguage = (newLanguage) => {
    const activeDocument = this.documents[this.activeDocumentIndex];
    if (activeDocument) {
      activeDocument.language = newLanguage;
      this.persistDocuments();
    }
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        const newDocument = this.createNewDocument(this.documents.length + 1, 'Text');
        newDocument.content = fileContent;
        newDocument.name = file.name;
        this.documents.push(newDocument);
        this.setActiveDocumentIndex(this.documents.length - 1);
        this.persistDocuments();
      };
      reader.onerror = () => console.error('Error reading file');
      reader.readAsText(file);
    }
  };
  

  onCloseTab(tabIndex) {
    this.documents.splice(tabIndex, 1);
    if (this.documents.length === 0) {
        this.addDocument(); // Add a new document if the last one was closed
    }
    this.setActiveDocumentIndex(Math.max(0, this.documents.length - 1));
    this.persistDocuments();
}


  renameDocument(tabIndex, newName) {
    if (tabIndex >= 0 && tabIndex < this.documents.length && newName) {
      this.documents[tabIndex].name = newName;
      this.persistDocuments();
    }
  }

  saveDocument() {
    const activeDocument = this.documents[this.activeDocumentIndex];
    console.log("Saving document content:", activeDocument.content);
    if (activeDocument) {
      const element = document.createElement("a");
      const file = new Blob([activeDocument.content], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = activeDocument.name;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  handlePrint() {
    const activeDocument = this.documents[this.activeDocumentIndex];
    if (activeDocument) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`<html><head><title>Print: ${activeDocument.name}</title></head><body>`);
      printWindow.document.write(`<pre>${activeDocument.content}</pre>`);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 250);
    }
  }

  transformText = (transformationType) => {
        const activeDocument = this.documents[this.activeDocumentIndex];
        if (activeDocument) {
        let transformedContent = '';
        switch (transformationType) {
            case 'capitalize':
            transformedContent = activeDocument.content.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            break;
            case 'lowercase':
            transformedContent = activeDocument.content.toLowerCase();
            break;
            case 'uppercase':
            transformedContent = activeDocument.content.toUpperCase();
            break;
            case 'inverseWord':
            transformedContent = activeDocument.content.split(/\s+/).reverse().join(' ');
            break;
            case 'inverseCharacters':
            transformedContent = activeDocument.content.split('').reverse().join('');
            break;
            default:
            transformedContent = activeDocument.content;
        }

        // Update the document's content
        activeDocument.content = transformedContent;
        this.persistDocuments();
    }
  }
  
  /* ********** EDIT Features ******** */
  WordWrap(limit) {
    const activeDocument = this.documents[this.activeDocumentIndex];
    if (activeDocument) {
      let wrappedText = '';
      const words = activeDocument.content.split(' ');
      let currentLine = '';
  
      words.forEach(word => {
        if ((currentLine + word).length > limit) {
          wrappedText += currentLine.trim() + '\n';
          currentLine = word + ' ';
        } else {
          currentLine += word + ' ';
        }
      });
  
      wrappedText += currentLine.trim();
      activeDocument.content = wrappedText;
      this.persistDocuments();
    }
  }
  
  /* ********************************* */

  persistDocuments() {
    localStorage.setItem('documentMetadata', JSON.stringify(this.documents));
    localStorage.setItem('activeDocumentIndex', this.activeDocumentIndex.toString());
  }
}

export const documentStore = new DocumentStore();
