import React from 'react';
import './../styles/Editor.css';
import languages from '../data/languages.json';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ext-language_tools'; 
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/theme-github'; // Use the "github" theme or another available theme

const Editor = ({ document, onChange, language, content }) => {

  const options = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2,
    mode: getMode(language),
    theme: 'github', // Use the "github" theme or another available theme
  };

  // Assuming a line height of about 20px
  const editorHeight = 20 * 18; // 20 lines * 20px per line

  return (
    <div className="Editor" style={{ height: `${editorHeight}px`, overflow: 'auto' }}>
      <AceEditor
        value={content || ''}
        onChange={onChange}
        width="100%"
        height="100%"
        fontSize={16}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          useWorker: false,
        }}
        {...options}
      />
    </div>
  );
};

const getMode = (language) => {
  try {
    const languageInfo = languages.languages.find((lang) => lang.name === language);
    if (languageInfo && languageInfo.mode) {
      require(`ace-builds/src-noconflict/mode-${languageInfo.mode}`); // Dynamically require the mode
      return languageInfo.mode;
    }
  } catch (error) {
    console.error(`Error setting mode for language ${language}:`, error);
  }
  return 'text'; // Fallback to 'text' mode in case of any errors
};

export default Editor;
