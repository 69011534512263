import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from './ConfirmationModal';
import './../styles/TabBar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const TabBar = ({ documentStore }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [newName, setNewName] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [tabToClose, setTabToClose] = useState(null); // State to store the index of the tab to close

  const startEdit = (index, name) => {
    setEditIndex(index);
    setNewName(name);
  };

  const endEdit = () => {
    if (editIndex !== null && newName.trim() !== '') {
      documentStore.renameDocument(editIndex, newName.trim());
    }
    setEditIndex(null);
  };

  const handleTabClose = (index, event) => {
    event.stopPropagation();
    const doc = documentStore.documents[index];
    if (doc.content.trim() === '') {
      documentStore.onCloseTab(index);
    } else {
      setTabToClose(index); // Set the index of the tab to be potentially closed
      setShowModal(true); // Show the confirmation modal
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmClose = () => {
    if (tabToClose !== null) {
      documentStore.onCloseTab(tabToClose);
      setTabToClose(null); // Reset the state
    }
    setShowModal(false); // Close the modal
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <>
      <Nav variant="tabs" className="custom-tab-bar" activeKey={documentStore.activeDocumentIndex} onSelect={(eventKey) => documentStore.setActiveDocumentIndex(parseInt(eventKey))}>
        {documentStore.documents.map((doc, index) => (
          <Nav.Item key={doc.id} className={`tab-item position-relative ${documentStore.activeDocumentIndex === index ? 'tab-link-active' : ''}`}>
            {editIndex === index ? (
              <input
                type="text"
                className="form-control rename-input"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onBlur={endEdit}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    endEdit();
                  }
                }}
                onFocus={handleFocus}
                autoFocus
              />
            ) : (
              <Nav.Link 
                eventKey={index.toString()} 
                className="tab-link" 
                onDoubleClick={() => startEdit(index, doc.name)}
              >         
                {doc.name}
                <span className="close-icon-container">
                  <CloseIcon 
                    fontSize="inherit" 
                    className="close-icon"
                    onClick={(event) => handleTabClose(index, event)}
                  />
                </span>
              </Nav.Link>
            )}
          </Nav.Item>
        ))}
      </Nav>

      {/* Confirmation Modal */}
      <ConfirmationModal 
        show={showModal}
        onHide={handleCloseModal}
        onConfirm={handleConfirmClose}
        title="Close Document"
        body="Are you sure you want to close this document?"
      />
    </>
  );
};

export default TabBar;
