import React from 'react';
import './../styles/Footer.css'; // Ensure this path is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-text">
        <strong>Disclaimer:</strong> Files are saved in browser storage. Cleaning up the storage will delete the files.<br />
        Files created in WebPad++ are stored locally in your browser and are not sent to or stored on our server. Usage is monitored solely for internal purposes.
      </div>
    </footer>
  );
};

export default Footer;
