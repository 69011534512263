import React from 'react';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './../styles/MenuIconBar.css';

const MenuIconBar = (
    { 
      onAddDocument, 
      onOpenDocument, 
      onSaveDocument, 
      onPrint, 
      onCapitalize, 
      onLowercase, 
      onUppercase, 
      onInverseWord, 
      onInverseCharacters,
      onWordWrap 
    }) => {
  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <Navbar bg="light" expand>
      <Nav>
        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "New Document")}>
          <Nav.Link onClick={onAddDocument}><i className="bi bi-file-earmark-plus"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Open Document")}>
          <Nav.Link onClick={onOpenDocument}><i className="bi bi-folder2-open"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Save")}>
          <Nav.Link onClick={onSaveDocument}><i className="bi bi-save"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Print")}>
          <Nav.Link onClick={onPrint}><i className="bi bi-printer"></i></Nav.Link>
        </OverlayTrigger>

        {/* Separator */}
        <Nav.Item><hr className="d-inline-block mx-2 vertical-separator" /></Nav.Item>

        {/* Wrap Text Icon */}
        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Word Wrap")}>
          <Nav.Link onClick={onWordWrap}><i className="bi bi-text-wrap"></i></Nav.Link>
        </OverlayTrigger>

        {/* Separator */}
        <Nav.Item><hr className="d-inline-block mx-2 vertical-separator" /></Nav.Item>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Capital Case")}>
          <Nav.Link onClick={onCapitalize}><i className="bi bi-type"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Upper Case")}>
          <Nav.Link onClick={onUppercase}><i className="bi bi-alphabet-uppercase"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Lower Case")}>
          <Nav.Link onClick={onLowercase}><i className="bi bi-alphabet"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Inverse Word")}>
          <Nav.Link onClick={onInverseWord}><i className="bi bi-arrow-left-right"></i></Nav.Link>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={props => renderTooltip(props, "Inverse Characters")}>
          <Nav.Link onClick={onInverseCharacters}><i className="bi bi-arrow-clockwise"></i></Nav.Link>
        </OverlayTrigger>
      </Nav>
    </Navbar>
  );
};

export default MenuIconBar;
