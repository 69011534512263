import React from 'react';
import './../styles/StatusBar.css';

const StatusBar = ({ content, language }) => {
  const wordCount = content ? content.trim().split(/\s+/).length : 0;
  const charCount = content ? content.length : 0;

  return (
    <div className="status-bar">
      <span>Words Count: {wordCount}</span>
      <span> Characters Count: {charCount}</span>
      <span> Language: {language || 'Text'}</span>
    </div>
  );
};

export default StatusBar;
