import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { documentStore } from './components/DocumentStore';
import Header from './components/Header';
import Footer from './components/Footer';
import MenuBar from './components/MenuBar';
import TabBar from './components/TabBar';
import Editor from './components/Editor';
import StatusBar from './components/StatusBar';
import MenuIconBar from './components/MenuIconBar';
import SideBanner from './components/TopBanner';
import ReactGA from 'react-ga';
import './App.css';
import TopBanner from './components/TopBanner';

const App = observer(() => {

  useEffect(() => {    
    ReactGA.initialize('G-RM2XLVYZCP');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const fileInputRef = useRef(null);
  const { documents, activeDocumentIndex, setActiveDocumentIndex, updateDocumentContent, addDocument, saveDocument, handlePrint, handleFileChange, onCloseTab, renameDocument, transformText } = documentStore;
  const activeDocument = documents[activeDocumentIndex];

  const onFileChange = (event) => {
    handleFileChange(event);
  };

  return (
    <div className="App">      

        <Header />
        <MenuBar
          onAddDocument={documentStore.addDocument}
          onOpenDocument={() => fileInputRef.current.click()}
          onSaveDocument={documentStore.saveDocument}
          onPrint={documentStore.handlePrint}
          onCapitalize={() => documentStore.transformText('capitalize')}
          onLowercase={() => documentStore.transformText('lowercase')}
          onUppercase={() => documentStore.transformText('uppercase')}
          onInverseWord={() => documentStore.transformText('inverseWord')}
          onInverseCharacters={() => documentStore.transformText('inverseCharacters')}
          onWordWrap={() => documentStore.WordWrap(80)}
          onLanguageSelect={documentStore.updateDocumentLanguage}
        />
        <MenuIconBar
          onAddDocument={addDocument}
          onOpenDocument={() => fileInputRef.current.click()}
          onSaveDocument={saveDocument}
          onPrint={handlePrint}
          onCapitalize={() => documentStore.transformText('capitalize')}
          onLowercase={() => documentStore.transformText('lowercase')}
          onUppercase={() => documentStore.transformText('uppercase')}
          onInverseWord={() => documentStore.transformText('inverseWord')}
          onInverseCharacters={() => documentStore.transformText('inverseCharacters')}       
          onWordWrap={() => documentStore.WordWrap(80)}   
        />
     
          <div className="workspace">
            <TabBar
              documents={documentStore.documents} 
              activeIndex={documentStore.activeDocumentIndex}
              documentStore={documentStore}
              onSetActive={setActiveDocumentIndex}
              onCloseTab={onCloseTab}
              onRenameTab={renameDocument}
            />
            <div className="editor-and-tabs">
              {documents.length > 0 && (
              <Editor 
                key={activeDocument.id}
                onChange={(newContent) => updateDocumentContent(newContent)}
                language={activeDocument.language}
                content={activeDocument.content}
              />
              )}
              <StatusBar content={activeDocument.content} language={activeDocument.language} />
              <Footer />
            </div>          
               
                      
      </div>      
      <input type="file" ref={fileInputRef} onChange={onFileChange} style={{ display: 'none' }} />
    </div>
  );
});

export default App;