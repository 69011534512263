import React from 'react';
import './../styles/MenuBar.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import languageData from '../data/languages.json'; // Adjust the path as needed

const MenuBar = ({ 
  onAddDocument, 
  onSaveDocument, 
  onOpenDocument, 
  onPrint, 
  onCapitalize, 
  onLowercase, 
  onUppercase, 
  onInverseWord, 
  onInverseCharacters, 
  onWordWrap,
  onLanguageSelect }) => {
  // Group languages by their first letter
  const groupedLanguages = languageData.languages.reduce((acc, language) => {
    const firstChar = language.name.charAt(0);
    acc[firstChar] = acc[firstChar] || [];
    acc[firstChar].push(language.name);
    return acc;
  }, {});

  return (
    <Navbar bg="light" exapnd>
      <Nav>
        {/* File Dropdown */}
        <NavDropdown title="File" id="file-dropdown">
          <NavDropdown.Item onClick={onAddDocument}>New Document</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={onSaveDocument}>Save</NavDropdown.Item>
          <NavDropdown.Item onClick={onOpenDocument}>Open...</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={onPrint}>Print</NavDropdown.Item>
        </NavDropdown>
      
        {/* Format Dropdown */}
        <NavDropdown title="Format" id="format-dropdown">
          <NavDropdown.Item onClick={onWordWrap}>Word Wrap</NavDropdown.Item>          
        </NavDropdown>

        {/* Tools Dropdown */}
        <NavDropdown title="Tools" id="tools-dropdown">
          <NavDropdown.Item onClick={onCapitalize}>Capital Case</NavDropdown.Item>
          <NavDropdown.Item onClick={onLowercase}>Lower Case</NavDropdown.Item>
          <NavDropdown.Item onClick={onUppercase}>Upper Case</NavDropdown.Item>
          <NavDropdown.Item onClick={onInverseWord}>Inverse Word</NavDropdown.Item>
          <NavDropdown.Item onClick={onInverseCharacters}>Inverse Characters</NavDropdown.Item>
        </NavDropdown>

        {/* Languages Dropdown */}
        <NavDropdown title="Language" id="language-dropdown">
          {Object.keys(groupedLanguages).sort().map(group => (
            <NavDropdown key={group} title={group} id={`group-dropdown-${group}`}>
              {groupedLanguages[group].map(language => (
                <NavDropdown.Item key={language} onClick={() => onLanguageSelect(language)}>
                  {language}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ))}
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

export default MenuBar;
